const environment = {
  API_URL: process.env.REACT_APP_API_URL,
  SELF_URL: process.env.REACT_APP_SELF_URL,
  DELAY_TIME: 1000,
  APPLIED_LOAN_STATUS: "APPLIED",
  REVIEWING_LOAN_STATUS: "REVIEWING",
  APPROVED_LOAN_STATUS: "APPROVED",
  REJECTED_LOAN_STATUS: "REJECTED",
  FUNDING_LOAN_STATUS: "FUNDING",
  DISBURSED_LOAN_STATUS: "DISBURSED",
  PAID_LOAN_STATUS: "PAID",
  LOAN_INSTALLMENT_MOVEMENT_TYPE: "LOAN_INSTALLMENT",
  OVERDUE_INTEREST_MOVEMENT_TYPE: "OVERDUE_INTEREST",
  PAYMENT_MOVEMENT_TYPE: "PAYMENT",
  PAYMENT_TERM_REDUCTION_MOVEMENT_TYPE: "PAYMENT_TERM_REDUCTION",
  PAYMENT_INSTALLMENT_AMOUNT_REDUCTION_MOVEMENT_TYPE: "PAYMENT_INSTALLMENT_AMOUNT_REDUCTION",
  MAX_PROOF_FILE_SIZE: 15000000,
  REQUESTED_WITHDRAWAL_STATUS: "REQUESTED",
  /* FIREBASE */
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  /* EPAYCO */
  EPAYCO_PUBLIC_KEY: process.env.REACT_APP_EPAYCO_PUBLIC_KEY,
  EPAYCO_TESTING: process.env.REACT_APP_EPAYCO_TESTING === "1",
};

export default environment;
